import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { } from 'rxjs';
declare interface window { __GLOBAL_VAR__: any }

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',

  })
};


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient,

  ) { }

  getSettings(): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    return this.http.get(baseUrl + "/api/Configuration");

  }

  getLocation(): Observable<any> {
    return this.http.get("https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location");
  }

  

}
