import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addStringVars'
})

// Value = 'Hello {-}. Where are my {-}.'
export class AddStringVarsPipe implements PipeTransform {

    transform(value: string, vars: string[]): string {

        if (!value || !vars.length || !value.includes('{-')) {
            return value;
        }

        vars.forEach((v: any) => {

            value = value.replace('{-}', v);

        });

        return value;
    }
}
