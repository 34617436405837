import { Injectable, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { } from 'rxjs';
declare interface window { __GLOBAL_VAR__: any }

@Injectable({
  providedIn: 'root'
})
export class ProductsService implements OnInit{

  domain: string;
  url: string;
  constructor(private http: HttpClient) {

  }

  ngOnInit() {

  }

  getProducts(): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    return this.http.get(baseUrl + "/api/Products");

  }

  getProduct(maintenanceId): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    return this.http.get(baseUrl + "/api/Products/" + maintenanceId);
  }

  getStoreProduct(productCode, licenses, duration, countryIsoCode, coupon): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    let xtrack = localStorage.getItem('xtrack');
    const params = new HttpParams()
      .set('licenses', licenses)
      .set('duration', duration)
      .set('countryIsoCode', countryIsoCode)
      .set('coupon', coupon)
      .set('x-track', xtrack);
    return this.http.get(baseUrl + "/api/Products/store/" + productCode, { params });
  }

  getProductScenarios(idSubClass, idVersionType, ml): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    const params = new HttpParams()
      .set('idSubClass', idSubClass)
      .set('idVersionType', idVersionType)
      .set('ml', ml);
    return this.http.get(baseUrl + "/api/Products/scenarios/", { params });
  }

  getRenewUrl(maintenanceId, productCode, licenses, subscriptionMonths, coupon, xtrack): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    let xyParamsJson = localStorage.getItem('xyParams');
    let xyParamsObj = JSON.parse(xyParamsJson);

    let countryCode = localStorage.getItem('countryIsoCode');
    //let xidTecnico = localStorage.getItem('xidTecnico');
    //let ypaymenttypes = localStorage.getItem('yPaymentTypes');
    //let xtrack2 = localStorage.getItem('xtrack2');
    //let xcoupon = localStorage.getItem('xcoupon');
    //let xpromo = localStorage.getItem('xpromo');
    //let xincentive = localStorage.getItem('xincentive');
    //let xpromocolor = localStorage.getItem('xpromocolor');
    let language = countryCode.split('-')[0];

    let UrlParameters = {
      ProductCode: productCode,
      Licenses: licenses,
      Duration: subscriptionMonths,
      CountryIsoCode: countryCode,
      OrderId: "",
      Coupon: coupon,
      UrlParameters: xyParamsObj,
      Language: language
    }
    const params = new HttpParams();

    return this.http.post(baseUrl + `/api/Products/${maintenanceId}/session-url`, UrlParameters, { params });
  }
}


