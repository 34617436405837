import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-clever',
    templateUrl: './clever.component.html',
    styleUrls: ['./clever.component.scss']
})

export class CleverComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {

        const hiddenFields = JSON.parse(sessionStorage.getItem('hiddenFields'));

        Object.keys(hiddenFields).forEach((key: string) => {

            if (hiddenFields[key]) {

                const inputField = (document.querySelector('#frmRenewOptionSelected #' + key) as HTMLInputElement);

                if (inputField) {

                    inputField.value = hiddenFields[key];

                }

            }

        });

        const form = document.getElementById('frmRenewOptionSelected') as HTMLFormElement;

        form.setAttribute('action', hiddenFields.SessionUrl);

        sessionStorage.removeItem('hiddenFields');

        (form as HTMLFormElement).submit();

    }

}
