import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ProductModel} from '../../models/product.model';
import {Subscription} from 'rxjs';
import {SettingsModel} from '../../models/settings.model';

@Component({
    selector: 'app-to-clipboard-modal',
    templateUrl: './to-clipboard-modal.component.html',
    styleUrls: ['./to-clipboard-modal.component.scss']
})
export class ToClipboardModalComponent implements OnInit, OnDestroy {

    resources: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { product: ProductModel, maintenanceId: string },
                public dialog: MatDialog) {}

    ngOnInit(): void {
      const resources = localStorage.getItem('resources');
      this.resources = JSON.parse(resources);
    }

    ngOnDestroy() {

    }
}
