import { Injectable } from '@angular/core';
import {HelpPhoneModel} from '../../models/help-phone.model';

@Injectable({
    providedIn: 'root'
})
export class HelpPhoneService {

    constructor() {}

    // supportedcultures no lo utilizamos, lo eliminamos de aquí

    // 1.- Comprobamos geoLocationCountry que viene de la cookie. Si está a null por defecto asignar el de la settings culture,
    // porque la cultura nunca va a ser null
    // 2.- Buscar country en helphones si no se encuentra asignar por defecto inglés que es US

    getHelpPhone(geoLocationCountry, helpPhones, culture) {

        let helpPhonesAux: HelpPhoneModel[];

        helpPhonesAux = !geoLocationCountry

            ? helpPhones.filter((helpPhone: HelpPhoneModel) =>
                helpPhone.Culture === culture)    // en-US === en-US

            : helpPhones.filter((helpPhone: HelpPhoneModel) =>
                helpPhone.Culture.includes(geoLocationCountry));  // en-US === US


        if (!helpPhonesAux || !helpPhonesAux.length) {

            helpPhonesAux = helpPhones.filter((helpPhone: HelpPhoneModel) =>
                helpPhone.Culture === 'en-US');

        }

        if (helpPhonesAux && helpPhonesAux.length) {

            localStorage.setItem('phone', helpPhonesAux[0].Phone);

            return helpPhonesAux[0].Phone;

        }

        localStorage.setItem('phone', '');

        return '';

    }

}
