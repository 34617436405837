import { Injectable } from '@angular/core';

declare const adobe: any;


declare let dataLayer: any;



@Injectable()
export class AdobeTargetService {

  private params = {};

  constructor(public window: Window) { }

  private waitForAdobe(): Promise<boolean> {

    return new Promise((resolve, reject) => {

      let counter = 0;

      const interval = setInterval(() => {

        counter++;

        if (counter <= 2000
          && (typeof adobe !== 'undefined'
            && adobe.target
            && typeof adobe.target.triggerView === 'function'
            && typeof adobe.target.getOffers === 'function')) {

          resolve(true);

          clearInterval(interval);

        } else if (counter > 2000) {

          reject(false);

          clearInterval(interval);

        }

      }, 1);

    });
  }

  private launchApplyOffers(viewName: string) {

    this.waitForAdobe().then(() => {

      adobe.target.getOffers({
        request: {
          prefetch: {
            views: [
              {
                parameters: this.params
              }
            ]
          }
        }
      }).then(response => {

        adobe.target.applyOffers({
          response
        }).then((applyResponse) => {

          return this.targetView(viewName);

        });

      });

    });

  }

  targetView(viewName: string) {

    this.waitForAdobe().then(() => {

      // Sanitize viewName to get rid of any trailing symbols derived from URL
      if (viewName.startsWith('#') || viewName.startsWith('/')) {
        viewName = viewName.substr(1);
      }

      // Validate if the Target Libraries are available on your website
      if (adobe.target
        && typeof adobe.target.triggerView === 'function') {

        adobe.target.triggerView(viewName);

        // tslint:disable-next-line:no-console

      }

    });

  }

  setDataLayer(data: object, viewName: string) {
    // reset datalayer object values
    function resetDataLayerValues() {
      for (var i = 0; i < dataLayer.length; i++) {
        var item = dataLayer[i];
        for (var key in item) {
          if (item.hasOwnProperty(key) && typeof item[key] !== 'function') {
            item[key] = '';
          }
        }
      }
    }
    resetDataLayerValues();

    // Google tag manager
    this.window.dataLayer.push(data);

    // Adobe target manager
    for (let i = 0; i < dataLayer.length; i++) {

      if (dataLayer[i].hasOwnProperty('clientId')) {

        dataLayer[i] = {
          ...dataLayer[i],
          ...data
        };

        for (const property in dataLayer[i]) {

          if (dataLayer[i].hasOwnProperty(property)
            && property !== 'at_property') {

            this.params[property] = dataLayer[i][property];

          }

        }

        break;
      }

    }

   // this.launchApplyOffers(viewName);

  }

}
