import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {ToClipboardModalComponent} from "../../modals/to-clipboard-modal/to-clipboard-modal.component";

@Component({
    selector: 'app-copy-clipboard',
    templateUrl: './copy-clipboard.component.html',
    styleUrls: ['./copy-clipboard.component.scss']
})

export class CopyClipboardComponent implements OnInit {

    @Input() toCopy: string;
    @Input() fontSize: string;
    @Input() disabled: boolean;

    assetsHref = environment.assetsHref;

    constructor(private matDialog: MatDialog) {}

    ngOnInit(): void {}

    onClickCopyCode(code) {

        if (this.toCopy) {
            this.matDialog.open(ToClipboardModalComponent, {
                minHeight: '6rem'
            });

            if (navigator.clipboard) {
                navigator.clipboard.writeText(code);
            }
        }

    }

}
