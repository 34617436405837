import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
declare interface window { __GLOBAL_VAR__: any }
import { } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',

  })
};


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient,

  ) { }

  login(user: any): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    return this.http.post(baseUrl + "/api/Account/authenticate-email", user, httpOptions);

  }

  loginWithEncryptedData(encryptedData: any): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    return this.http.post(baseUrl + "/api/Account/authenticate-encryptedData", encryptedData, httpOptions)
  }

  loginWithSerialNumber(data: any): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    return this.http.post(baseUrl + "/api/Account/authenticate-serialnumber", data, httpOptions)
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    localStorage.getItem('token');
  }

  isLoggedIn() {
    return (typeof localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== null);
  }



}
