import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { } from 'rxjs';
declare interface window { __GLOBAL_VAR__: any }

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(
    private http: HttpClient,

  ) { }

  getResources(culture): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    return this.http.get(baseUrl + "/api/Configuration/resources/" + culture);

  }

  getFeatures(culture): Observable<any> {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    return this.http.get( baseUrl + "/api/Configuration/resources/features/" + culture);
  }

}
