import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { MatIconModule } from '@angular/material/icon';


import { AuthInterceptorService } from './services/auth-interceptor/auth-interceptor.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NavfooterComponent } from './components/navfooter/navfooter.component';
import { HomeComponent } from './components/home/home.component';
import { ProductComponent } from './components/product/product.component';
import { Error404Component } from './components/error-404/error-404.component';
import { Error500Component } from './components/error-500/error-500.component';
import { CleverComponent } from './components/clever/clever.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToClipboardModalComponent } from './modals/to-clipboard-modal/to-clipboard-modal.component';
import { RenewModalComponent } from './modals/renew-modal/renew-modal.component';
import { CopyClipboardComponent } from './shared/copy-clipboard/copy-clipboard.component';
import { AdobeTargetService } from './services/adobe-target/adobe-target.service';

import { AddStringVarsPipe } from './pipes/add-string-vars.pipe';

import {
  MatProgressSpinnerModule
} from '@angular/material';


declare global {
  interface Window { dataLayer: any[]; }
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    NavfooterComponent,
    HomeComponent,
    LoginComponent,
    ProductComponent,
    ToClipboardModalComponent,
    CopyClipboardComponent,
    RenewModalComponent,
    Error404Component,
    Error500Component,
    CleverComponent,
    AddStringVarsPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    MatIconModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: '00/products', component: HomeComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'product/:maintenanceId', component: ProductComponent },
      { path: 'error-404', component: Error404Component },
      { path: 'error-500', component: Error500Component },
      { path: 'clever/bridge', component: CleverComponent },
    ], { scrollPositionRestoration: 'enabled' }),
    BrowserAnimationsModule,
    MaterialModule,
    MatProgressSpinnerModule
  ],
  exports: [MatProgressSpinnerModule, MaterialModule, RouterModule],
  providers: [AdobeTargetService, AddStringVarsPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  }, {
      provide: Window,
      useValue: window,
    }],
  entryComponents: [
    ToClipboardModalComponent,
    RenewModalComponent

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
