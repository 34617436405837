import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-error-404',
    templateUrl: './error-404.component.html',
    styleUrls: ['./error-404.component.scss']
})
export class Error404Component implements OnInit, OnDestroy {

    assetsHref = environment.assetsHref;

    resources: any;

    constructor() {}

    ngOnInit(): void {

    }

    ngOnDestroy() {

    }

}
