import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from '../../models/product.model';
import { Subscription } from 'rxjs';
import { SettingsModel } from '../../models/settings.model';
import { ProductsService } from "../../services/products/products.service";
import { AdobeTargetService } from '../../services/adobe-target/adobe-target.service';
import { SettingsService } from "../../services/settings/settings.service";
import { ResourcesService } from "../../services/resources/resources.service";

@Component({
  selector: 'app-renew-modal',
  templateUrl: './renew-modal.component.html',
  styleUrls: ['./renew-modal.component.scss']
})
export class RenewModalComponent implements OnInit, OnDestroy {

  loading: boolean = true;
  loading2: boolean;
  assetsHref = environment.assetsHref;
  resources: any;
  product: ProductModel;
  maintenanceLicenses: string;
  subscriptionMonths: string;
  productWPrices: any;
  productCode: string;
  culture: string;
  coupon: string;
  xtrack: string;
  productPrice: string;
  promoPrice: string;
  discount: number;
  idSubClassFound: boolean;
  url: string;
  deviceType: string;
  countryIsoCode: string;
  maintenanceLicenseNum: number;
  recommendedMaintenanceLicense: number;
  innerWidth: number;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: { product: ProductModel, recommended: string, recommendedOnPage: string},
    public dialog: MatDialog,
    public productsService: ProductsService,
    private adobeTargetService: AdobeTargetService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public settingsService: SettingsService,
    public resourcesService: ResourcesService) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    const resources = localStorage.getItem('resources');
    this.resources = JSON.parse(resources);

    this.countryIsoCode = localStorage.getItem('countryIsoCode');

    this.culture = localStorage.getItem('culture');
    this.coupon = localStorage.getItem('coupon');
    this.xtrack = localStorage.getItem('xtrack');

    this.url = this.router['location']._platformLocation.location.origin;

    if (this.activatedRoute.snapshot.url[1]) {
      this.url = this.url + '/product/' + this.activatedRoute.snapshot.url[1].path;

    }

      this.settingsService.getSettings().subscribe(data => {

        this.culture = data.Localization.Culture;

        localStorage.setItem('culture', data.Localization.Culture);
        this.countryIsoCode = data.Localization.CountryIsoCode;
        localStorage.setItem('countryIsoCode', this.countryIsoCode);

        this.resourcesService.getResources(this.culture).subscribe(data => {

          this.resources = data;
          localStorage.setItem('resources', JSON.stringify(data));

          this.loading = true;
          if (this.data.recommended) {


            this.productsService.getProductScenarios(this.data.recommended, 2, this.culture).subscribe(data => {

              for (let x = 0; x < data.length; x++) {

                for (let y = 0; y < data[x].ColDevices.length; y++) {

                  if (data[x].ColDevices[y].idsubclase == this.data.recommended) {
                    console.log('id found');
                    this.idSubClassFound = true;

                    let licenses = parseInt(data[x].ColDevices[y].devices_code, 10);

                    if (data[x].ColDevices[y].devices_code == -1 || data[x].ColDevices[y].devices_code == 'IL') {

                      this.maintenanceLicenses = 'MULTI';
                    } else if (data[x].ColDevices[y].devices_code == 2) {

                      this.maintenanceLicenses = '3PC';
                    } else {

                      this.maintenanceLicenses = licenses + 'PC';
                    }

                    this.recommendedMaintenanceLicense = licenses;
                    this.productCode = data[x].code;


                    this.subscriptionMonths = this.data.product.SubscriptionMonths;


                    if (this.data.product.SubscriptionMonths == '0') {
                      this.subscriptionMonths = '12';
                    }
                  } else {

                  }
                }
                if (this.idSubClassFound != true) {

                  this.product = this.data.product;
                  this.productCode = this.data.product.Code;
                  this.recommendedMaintenanceLicense = this.product.MaintenanceLicenses;
                  this.maintenanceLicenses = this.product.MaintenanceLicenses + 'PC';
                  this.maintenanceLicenseNum = +(this.product.MaintenanceLicenses);
                  if (this.product.MaintenanceLicenses == -1) { this.maintenanceLicenseNum = 20 }

                  if (this.product.MaintenanceLicenses == -1) {
                    this.maintenanceLicenses = 'MULTI';
                  }
                  else if (this.product.MaintenanceLicenses == 2) {

                    this.maintenanceLicenses = '3PC';
                  }
                  this.subscriptionMonths = this.product.SubscriptionMonths;
                  if (this.product.SubscriptionMonths == '0') {
                    this.subscriptionMonths = '12';
                  }
                }
              }

              let d = new Date();
              d.getHours();
              d.getMinutes();
              d.getSeconds();

              let dayOfWeek = d.getDay();
              let weekOrWeekend = ''
              let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
              if (isWeekend == true) {
                weekOrWeekend = 'weekend'
              } else {
                weekOrWeekend = 'week'
              }

              let productStatus = '';
              if (this.data.product.ProductStatus.Status == 1) {
                productStatus = 'active';
              } else {
                productStatus = 'expired';
              }

              let pipe = new DatePipe('en-US');
              const subscriptionEndDate = pipe.transform(this.data.product.ExpirationDate, 'dd/MM/yyyy');

              this.adobeTargetService.setDataLayer({
                event: 'gtm.datalayer',
                eventType: 'pageview',
                eventCategory: '',
                eventAction: '',
                eventLabel: '',
                eventValue: '',
                pageName: 'renewal area:products and services.product.suggested renewal',
                pageSection: 'renewal area',
                pageCategory: 'products and services',
                pageSubcategory1: 'product',
                pageSubcategory2: 'suggested renewal',
                pageSubcategory3: '',
                pageSubcategory4: '',
                url: 'https://renewals.pandasecurity.com/product/' + data.MaintenanceId,
                previousPage: '',
                referer: '',
                originURL: localStorage.getItem('url'),
                accessType: this.deviceType,
                hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
                day: new Date().toLocaleString('en-us', { weekday: 'long' }),
                weekday: weekOrWeekend,
                language: this.culture.substr(0, 2),
                pageCode: '200-ok',
                pageAccess: 'private',
                userLanguage: this.culture.substr(0, 2),
                clientType: 'particular',
                clientCreatedDate: '',
                sessionLoginStatus: 'logged',
                productName: this.data.product.Name,
                clientId: this.data.product.CustomerId,
                clientIdList: this.data.product.CustomerId,
                productId: this.data.product.MaintenanceId,
                activationCode: this.data.product.ActivationCode,
                productSKU: '',
                productState: productStatus,
                productDevices: this.data.product.MaintenanceLicenses,
                productMonths: this.data.product.SubscriptionMonths,
                productSubscriptionType: this.data.product.SubscriptionType,
                productSubscriptionStartDate: '',
                productSubscriptionEndDate: subscriptionEndDate,
                productsCode: this.data.product.Code,
                productsVersionType: this.data.product.VersionType,

              }, 'suggested renewal');



              this.productsService.getStoreProduct(this.productCode, this.maintenanceLicenses, this.subscriptionMonths, this.countryIsoCode, this.coupon).subscribe(data => {

                this.loading = false;

                this.productWPrices = data;

                this.productPrice = this.productWPrices.PriceLine;

                this.promoPrice = this.productWPrices.PromoLine;

                this.discount = this.productWPrices.Discount;

              },
                error => {
                  console.log(error);
                  this.loading = false;
                });
            }, error => {
              console.log(error);
              this.loading = false;
            });

          }
          else {
            console.log('else');
            this.loading = true;
            this.product = this.data.product;
            this.productCode = this.data.product.Code;
            this.maintenanceLicenses = this.product.MaintenanceLicenses + 'PC';
            this.maintenanceLicenseNum = +(this.product.MaintenanceLicenses);
            if (this.product.MaintenanceLicenses == -1) { this.maintenanceLicenseNum = 20 }

            if (this.product.MaintenanceLicenses == -1) {
              this.maintenanceLicenses = 'MULTI';
            }
            else if (this.product.MaintenanceLicenses == 2) {

              this.maintenanceLicenses = '3PC';
            }
            this.subscriptionMonths = this.product.SubscriptionMonths;
            if (this.product.SubscriptionMonths == '0') {
              this.subscriptionMonths = '12';
            }

            let d = new Date();
            d.getHours();
            d.getMinutes();
            d.getSeconds();

            let dayOfWeek = d.getDay();
            let weekOrWeekend = ''
            let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
            if (isWeekend == true) {
              weekOrWeekend = 'weekend'
            } else {
              weekOrWeekend = 'week'
            }

            let productStatus = '';
            if (this.data.product.ProductStatus.Status == 1) {
              productStatus = 'active';
            } else {
              productStatus = 'expired';
            }

            let pipe = new DatePipe('en-US');
            const subscriptionEndDate = pipe.transform(this.data.product.ExpirationDate, 'dd/MM/yyyy');

            if (this.culture) {
              this.adobeTargetService.setDataLayer({
                event: 'gtm.datalayer',
                eventType: 'pageview',
                eventCategory: '',
                eventAction: '',
                eventLabel: '',
                eventValue: '',
                pageName: 'renewal area:products and services.product.suggested renewal',
                pageSection: 'renewal area',
                pageCategory: 'products and services',
                pageSubcategory1: 'product',
                pageSubcategory2: 'suggested renewal',
                pageSubcategory3: '',
                pageSubcategory4: '',
                url: 'https://renewals.pandasecurity.com/product/' + this.data.product.MaintenanceId,
                previousPage: 'renewal area:products and services',
                referer: 'https://renewals.pandasecurity.com/',
                originURL: localStorage.getItem('url'),
                accessType: this.deviceType,
                hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
                day: new Date().toLocaleString('en-us', { weekday: 'long' }),
                weekday: weekOrWeekend,
                language: this.culture.substr(0, 2),
                pageCode: '200-ok',
                pageAccess: 'private',
                clientId:  this.data.product.CustomerId,
                clientIdList: this.data.product.CustomerId,
                userLanguage: this.culture.substr(0, 2),
                clientType: 'particular',
                clientCreatedDate: '',
                sessionLoginStatus: 'logged',
                productName: this.data.product.Name,
                productId: this.data.product.MaintenanceId,
                activationCode: this.data.product.ActivationCode,
                productSKU: '',
                productState: productStatus,
                productDevices: this.data.product.MaintenanceLicenses,
                productMonths: this.data.product.SubscriptionMonths,
                productSubscriptionType: this.data.product.SubscriptionType,
                productSubscriptionStartDate: '',
                productSubscriptionEndDate: subscriptionEndDate,
                productsCode: this.data.product.Code,
                productsVersionType: this.data.product.VersionType,

              }, 'products and services.product.suggested renewal');
            }
            this.productsService.getStoreProduct(this.productCode, this.maintenanceLicenses, this.subscriptionMonths, this.countryIsoCode, this.coupon).subscribe(data => {

              this.productWPrices = data;

              this.productPrice = this.productWPrices.PriceLine;

              this.promoPrice = this.productWPrices.PromoLine;

              this.discount = this.productWPrices.Discount;

              this.loading = false;


              error => {
                console.log(error);
                this.loading = false;
              }
            });
          }




          error => {
            console.log(error);
          }
        });

        error => {
          console.log(error);
        }
      });
 
    function isMobile() {
      var check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
          check = true;
      })(navigator.userAgent);
      return check;
    };

    let deviceType = '';
    if (isMobile() == true) {
      this.deviceType = 'Mobile'
    } else {
      this.deviceType = 'Desktop'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

  }

  onChangeDevices(devices, duration) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let devicesCode = '';
    if (devices.target.value == '3PC') {
      devicesCode = '03';
    } else if (devices.target.value == '5PC') {
      devicesCode = '05'
    } else if (devices.target.value == '10PC') {
      devicesCode = '10'
    } else if (devices.target.value == 'MULTI') {
      devicesCode = '25'
    }
    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'suggested renewal',
      eventAction: 'new devices',
      eventValue: devicesCode,
      eventLabel: this.product.ActivationCode,
      pageName: 'renewal area:products and services.product.suggested renewal',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: 'suggested renewal',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: '',
      referer: '',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: this.data.product.CustomerId,
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.data.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product.suggested renewal');

    this.loading2 = true;
    this.productsService.getStoreProduct(this.productCode, devices.target.value, duration, this.countryIsoCode, this.coupon).subscribe(data => {

      this.productWPrices = data;

      this.productPrice = this.productWPrices.PriceLine;

      this.promoPrice = this.productWPrices.PromoLine;

      this.discount = this.productWPrices.Discount;

      this.loading2 = false;


      error => {
        console.log(error);
        this.loading = false;
      }
    });
  }
  onChangeDuration(duration, devices) {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    if (this.product.ProductStatus.Status == 1) {
      productStatus = 'active';
    } else {
      productStatus = 'expired';
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'suggested renewal',
      eventAction: 'new months',
      eventLabel: this.product.ActivationCode,
      eventValue: duration.target.value,
      pageName: 'renewal area:products and services.product.suggested renewal',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: 'suggested renewal',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: '',
      referer: '',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: this.data.product.CustomerId,
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.data.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product.suggested renewal');


    this.loading2 = true;
    this.productsService.getStoreProduct(this.productCode, devices, duration.target.value, this.countryIsoCode, this.coupon).subscribe(data => {

      this.productWPrices = data;

      this.productPrice = this.productWPrices.PriceLine;

      this.promoPrice = this.productWPrices.PromoLine;

      this.discount = this.productWPrices.Discount;

      this.loading2 = false;


      error => {
        console.log(error);
        this.loading = false;
      }
    });
  }

  onClickGoRenew(numOfDevices, duration) {

    this.loading2 = true;
    this.productsService.getRenewUrl(this.data.product.MaintenanceId, this.productCode, numOfDevices, duration, this.coupon, this.xtrack).subscribe(data => {
      this.dialog.closeAll();
      this.loading2 = false;
      let d = new Date();
      d.getHours();
      d.getMinutes();
      d.getSeconds();

      let dayOfWeek = d.getDay();
      let weekOrWeekend = ''
      let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
      if (isWeekend == true) {
        weekOrWeekend = 'weekend'
      } else {
        weekOrWeekend = 'week'
      }

      function pad(num, size) {
        var s = "000000000" + num;
        return s.substr(s.length - size);
      }

      let productStatus = '';
      if (this.data.product.ProductStatus.Status == 1) {
        productStatus = 'active';
      } else {
        productStatus = 'expired';
      }

      let pipe = new DatePipe('en-US');
      const subscriptionEndDate = pipe.transform(this.data.product.ExpirationDate, 'dd/MM/yyyy');

      this.adobeTargetService.setDataLayer({
        event: 'event.generic',
        eventType: 'event',
        eventCategory: 'suggested renewal',
        eventAction: 'renew',
        eventLabel: this.productCode,
        eventValue: '',
        pageName: 'renewal area:products and services.product.suggested renewal',
        pageSection: 'renewal area',
        pageCategory: 'products and services',
        pageSubcategory1: 'product',
        pageSubcategory2: 'suggested renewal',
        pageSubcategory3: '',
        pageSubcategory4: '',
        url: this.url,
        previousPage: '',
        referer: '',
        originURL: localStorage.getItem('url'),
        accessType: this.deviceType,
        hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
        day: new Date().toLocaleString('en-us', { weekday: 'long' }),
        weekday: weekOrWeekend,
        language: this.culture.substr(0, 2),
        pageCode: '200-ok',
        pageAccess: 'private',
        clientId:  this.data.product.CustomerId,
        clientIdList: this.data.product.CustomerId,
        userLanguage: this.culture.substr(0, 2),
        clientType: 'particular',
        clientCreatedDate: '',
        sessionLoginStatus: 'logged',
        productName: this.data.product.Name,
        productId: this.data.product.MaintenanceId,
        activationCode: this.data.product.ActivationCode,
        productSKU: '',
        productState: productStatus,
        productDevices: this.data.product.MaintenanceLicenses,
        productMonths: this.data.product.SubscriptionMonths,
        productSubscriptionType: this.data.product.SubscriptionType,
        productSubscriptionStartDate: '',
        productSubscriptionEndDate: subscriptionEndDate,
        productsCode: localStorage.getItem('products'),
        productsVersionType: localStorage.getItem('productsVersion'),
      }, 'products and services.product.product.suggested renewal');
      sessionStorage.setItem('hiddenFields', JSON.stringify(data));
      this.router.navigate(['clever/bridge']);
    },
      error => {
        this.loading = false;
        console.log(error);

      });
  }

  onClickSelectDevicesDatalayer() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';

    if (typeof this.product !== 'undefined') {
      if (this.product.ProductStatus.Status == 1) {
        productStatus = 'active';
      } else {
        productStatus = 'expired';
      }
    } else {
      this.product = {
        "Name": "",
        "Type": 15,
        "Code": "",
        "Range": "",
        "SubClassId": 0,
        "Image": "",
        "ActivationCode": "",
        "MaintenanceId": "",
        "Active": false,
        "ExpirationDate": "",
        "MaintenanceLicenses": 0,
        "ExpirationDays": 0,
        "AllowedLicenses": 0,
        "LicenseExpires": true,
        "LicenseIsReleasable": false,
        "LicenseIsRenewable": true,
        "OperatingSystem": "Windows",
        "IsInformationConsolidated": true,
        "DataBankUrl": "",
        "AutoRenewStatus": null,
        "VersionType": 2,
        "IsCancelSubscriptionEnabled": false,
        "IsActivateSubscriptionEnabled": false,
        "ProductYear": 17,
        "SubscriptionType": 0,
        "SubscriptionMonths": '0',
        "CustomerId": "",
        "DownloadUrl": null,
        "RenewUrl": "http://rshop.pandasecurity.com/cgi-bin/shop/?twds=myaccount&mode=renewal&Data=394439454138394539343941394439454138383936363733364536363934394641383942394541394131364338324135&track=191186",
        "UpgradeUrl": null,
        "ShopUrl": "http://buytrial.pandasecurity.com/index.php?productID=DOME&PLID=7002&track=187526",
        "Functionalities": [
          {
            "Module": "ANTIVIRUS",
            "Icon": "icon-antivirus",
            "Platforms": [
              "Windows",
              "Android",
              "Mac"
            ]
          },
          {
            "Module": "VPN_FREE",
            "Icon": "icon-vpn-free",
            "Platforms": [
              "Windows",
              "Android",
              "Mac",
              "iOS"
            ]
          },
          {
            "Module": "WIFI_PROTECTION",
            "Icon": "icon-wifi-protection",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "URL_FILTERING",
            "Icon": "icon-safe-browsing",
            "Platforms": [
              "Windows",
              "Mac"
            ]
          },
          {
            "Module": "FIREWALL",
            "Icon": "icon-personal-firewall",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "USB_VACCINE",
            "Icon": "icon-usb-protection",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "VIRTUAL_KEYBOARD",
            "Icon": "icon-virtual-keyboard",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "APPLICATION_CONTROL",
            "Icon": "icon-application-control",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "PROCESS_MONITOR",
            "Icon": "icon-process-monitor",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "SECURITY_NEWS",
            "Icon": "icon-security-news",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "SUPPORT",
            "Icon": "icon-standard-support",
            "Platforms": [
              "Windows",
              "Android",
              "Windows",
              "Android"
            ]
          },
          {
            "Module": "ANTITHEFT_BASIC",
            "Icon": "icon-antitheft-mobile",
            "Platforms": [
              "Android",
              "iOS"
            ]
          },
          {
            "Module": "APPLOCK",
            "Icon": "icon-app-lock",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "CALL_BLOCKER",
            "Icon": "icon-call-blocker",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "MOTION_ALARM",
            "Icon": "icon-motion-alert",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "THEFT_ALERT",
            "Icon": "icon-theft-alert",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "REMOTE_ALARM",
            "Icon": "icon-remote-alarm",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "PRIVACY_AUDITOR",
            "Icon": "icon-privacy-auditor",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "REMOTE_PHOTO",
            "Icon": "icon-remote-photo",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "DARKWEB_FREE",
            "Icon": "icon-dark-web-scanner",
            "Platforms": [
              "Windows",
              "Android",
              "Mac",
              "iOS"
            ]
          }
        ],
        "InStoreFunctionalities": [
          {
            "Code": "PLAN_1",
            "Icon": "icon-antivirus-multidevice",
            "Order": 1
          },
          {
            "Code": "PLAN_2",
            "Icon": "icon-vpn-free",
            "Order": 2
          },
          {
            "Code": "PLAN_3",
            "Icon": "icon-wifi-protection",
            "Order": 3
          },
          {
            "Code": "PLAN_4",
            "Icon": "icon-safe-browsing-shopping",
            "Order": 4
          }
        ],
        "DownloadPlatforms": [
          {
            "Url": null,
            "TypeOfService": 1
          },
          {
            "Url": "http://go.pandasecurity.com/mobile-security/?utm_source=download_web",
            "TypeOfService": 3
          },
          {
            "Url": "http://go.pandasecurity.com/ios-protection/?utm_source=download_web",
            "TypeOfService": 4
          },
          {
            "Url": "https://download.pandasecurity.com/thankyou/mac/?customID=190611&utm_source=download_web",
            "TypeOfService": 6
          }
        ],
        "LicenseStatus": {
          "Status": 7,
          "Color": 3,
          "Resource": "ProductInfo_Subscription_Status_Cancelled"
        },
        "menuOpacity" : false,
        "ProductStatus": {
          "Status": 7,
          "Color": 3,
          "Resource": "ProductInfo_Status_Expired",
          "ShowBand": true,
          "Ctas": [
            {
              "Url": "modal:renew",
              "Resource": "ProductInfo_CTA_RenewSubscription"
            }
          ]
        }
      }
    }
    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.data.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'suggested renewal',
      eventAction: 'devices',
      eventLabel: this.data.product.ActivationCode,
      eventValue: '',
      pageName: 'renewal area:products and services.product.suggested renewal',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: 'suggested renewal',
      url: this.url,
      previousPage: '',
      referer: '',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: this.data.product.CustomerId,
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.data.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.data.product.MaintenanceLicenses,
      productMonths: this.data.product.SubscriptionMonths,
      productSubscriptionType: this.data.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product.suggested renewal');

  }
  onClickSelectDurationDatalayer() {

    let d = new Date();
    d.getHours();
    d.getMinutes();
    d.getSeconds();

    let dayOfWeek = d.getDay();
    let weekOrWeekend = ''
    let isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
    if (isWeekend == true) {
      weekOrWeekend = 'weekend'
    } else {
      weekOrWeekend = 'week'
    }

    function pad(num, size) {
      var s = "000000000" + num;
      return s.substr(s.length - size);
    }

    let productStatus = '';
    console.log('product2', this.product);
    if (typeof this.product !== 'undefined') {
      if (this.product.ProductStatus.Status == 1) {
        productStatus = 'active';
      } else {
        productStatus = 'expired';
      }
    } else {
      this.product = {
        "Name": "",
        "Type": 15,
        "Code": "",
        "Range": "",
        "SubClassId": 0,
        "Image": "",
        "ActivationCode": "",
        "MaintenanceId": "",
        "Active": false,
        "ExpirationDate": "",
        "MaintenanceLicenses": 0,
        "ExpirationDays": 0,
        "AllowedLicenses": 0,
        "LicenseExpires": true,
        "LicenseIsReleasable": false,
        "LicenseIsRenewable": true,
        "OperatingSystem": "Windows",
        "IsInformationConsolidated": true,
        "DataBankUrl": "",
        "AutoRenewStatus": null,
        "VersionType": 2,
        "IsCancelSubscriptionEnabled": false,
        "IsActivateSubscriptionEnabled": false,
        "ProductYear": 17,
        "SubscriptionType": 0,
        "SubscriptionMonths": '0',
        "CustomerId": "",
        "DownloadUrl": null,
        "RenewUrl": "http://rshop.pandasecurity.com/cgi-bin/shop/?twds=myaccount&mode=renewal&Data=394439454138394539343941394439454138383936363733364536363934394641383942394541394131364338324135&track=191186",
        "UpgradeUrl": null,
        "ShopUrl": "http://buytrial.pandasecurity.com/index.php?productID=DOME&PLID=7002&track=187526",
        "Functionalities": [
          {
            "Module": "ANTIVIRUS",
            "Icon": "icon-antivirus",
            "Platforms": [
              "Windows",
              "Android",
              "Mac"
            ]
          },
          {
            "Module": "VPN_FREE",
            "Icon": "icon-vpn-free",
            "Platforms": [
              "Windows",
              "Android",
              "Mac",
              "iOS"
            ]
          },
          {
            "Module": "WIFI_PROTECTION",
            "Icon": "icon-wifi-protection",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "URL_FILTERING",
            "Icon": "icon-safe-browsing",
            "Platforms": [
              "Windows",
              "Mac"
            ]
          },
          {
            "Module": "FIREWALL",
            "Icon": "icon-personal-firewall",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "USB_VACCINE",
            "Icon": "icon-usb-protection",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "VIRTUAL_KEYBOARD",
            "Icon": "icon-virtual-keyboard",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "APPLICATION_CONTROL",
            "Icon": "icon-application-control",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "PROCESS_MONITOR",
            "Icon": "icon-process-monitor",
            "Platforms": [
              "Windows"
            ]
          },
          {
            "Module": "SECURITY_NEWS",
            "Icon": "icon-security-news",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "SUPPORT",
            "Icon": "icon-standard-support",
            "Platforms": [
              "Windows",
              "Android",
              "Windows",
              "Android"
            ]
          },
          {
            "Module": "ANTITHEFT_BASIC",
            "Icon": "icon-antitheft-mobile",
            "Platforms": [
              "Android",
              "iOS"
            ]
          },
          {
            "Module": "APPLOCK",
            "Icon": "icon-app-lock",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "CALL_BLOCKER",
            "Icon": "icon-call-blocker",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "MOTION_ALARM",
            "Icon": "icon-motion-alert",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "THEFT_ALERT",
            "Icon": "icon-theft-alert",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "REMOTE_ALARM",
            "Icon": "icon-remote-alarm",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "PRIVACY_AUDITOR",
            "Icon": "icon-privacy-auditor",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "REMOTE_PHOTO",
            "Icon": "icon-remote-photo",
            "Platforms": [
              "Android"
            ]
          },
          {
            "Module": "DARKWEB_FREE",
            "Icon": "icon-dark-web-scanner",
            "Platforms": [
              "Windows",
              "Android",
              "Mac",
              "iOS"
            ]
          }
        ],
        "InStoreFunctionalities": [
          {
            "Code": "PLAN_1",
            "Icon": "icon-antivirus-multidevice",
            "Order": 1
          },
          {
            "Code": "PLAN_2",
            "Icon": "icon-vpn-free",
            "Order": 2
          },
          {
            "Code": "PLAN_3",
            "Icon": "icon-wifi-protection",
            "Order": 3
          },
          {
            "Code": "PLAN_4",
            "Icon": "icon-safe-browsing-shopping",
            "Order": 4
          }
        ],
        "DownloadPlatforms": [
          {
            "Url": null,
            "TypeOfService": 1
          },
          {
            "Url": "http://go.pandasecurity.com/mobile-security/?utm_source=download_web",
            "TypeOfService": 3
          },
          {
            "Url": "http://go.pandasecurity.com/ios-protection/?utm_source=download_web",
            "TypeOfService": 4
          },
          {
            "Url": "https://download.pandasecurity.com/thankyou/mac/?customID=190611&utm_source=download_web",
            "TypeOfService": 6
          }
        ],
        "LicenseStatus": {
          "Status": 7,
          "Color": 3,
          "Resource": "ProductInfo_Subscription_Status_Cancelled"
        },
        "menuOpacity": false,
        "ProductStatus": {
          "Status": 7,
          "Color": 3,
          "Resource": "ProductInfo_Status_Expired",
          "ShowBand": true,
          "Ctas": [
            {
              "Url": "modal:renew",
              "Resource": "ProductInfo_CTA_RenewSubscription"
            }
          ]
        }
      }
    }

    let pipe = new DatePipe('en-US');
    const subscriptionEndDate = pipe.transform(this.product.ExpirationDate, 'dd/MM/yyyy');

    this.adobeTargetService.setDataLayer({
      event: 'event.generic',
      eventType: 'event',
      eventCategory: 'suggested renewal',
      eventAction: 'months',
      eventLabel: this.product.ActivationCode,
      eventValue: '',
      pageName: 'renewal area:products and services.product.suggested renewal',
      pageSection: 'renewal area',
      pageCategory: 'products and services',
      pageSubcategory1: 'product',
      pageSubcategory2: 'suggested renewal',
      pageSubcategory3: '',
      pageSubcategory4: '',
      url: this.url,
      previousPage: '',
      referer: '',
      originURL: localStorage.getItem('url'),
      accessType: this.deviceType,
      hour: pad(d.getHours(), 2) + ':' + pad(d.getMinutes(), 2) + ':' + pad(d.getSeconds(), 2),
      day: new Date().toLocaleString('en-us', { weekday: 'long' }),
      weekday: weekOrWeekend,
      language: this.culture.substr(0, 2),
      pageCode: '200-ok',
      pageAccess: 'private',
      clientId: sessionStorage.getItem('idcliente') || this.data.product.CustomerId,
      clientIdList: this.product.CustomerId,
      userLanguage: this.culture.substr(0, 2),
      clientType: 'particular',
      clientCreatedDate: '',
      sessionLoginStatus: 'logged',
      productName: this.product.Name,
      productId: this.product.MaintenanceId,
      activationCode: this.data.product.ActivationCode,
      productSKU: '',
      productState: productStatus,
      productDevices: this.product.MaintenanceLicenses,
      productMonths: this.product.SubscriptionMonths,
      productSubscriptionType: this.product.SubscriptionType,
      productSubscriptionStartDate: '',
      productSubscriptionEndDate: subscriptionEndDate,
      productsCode: localStorage.getItem('products'),
      productsVersionType: localStorage.getItem('productsVersion'),
    }, 'products and services.product.product.suggested renewal');

  }

  ngOnDestroy() {

  }
}
