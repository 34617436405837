import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    public dialog: MatDialog,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('token');

    let request = req;

    if (request.url.indexOf("authenticate-email") > -1) {

      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          console.log('error', err.status);

          if (err.status == 401) {
            console.log('error 401!');
          }

          return throwError(err);

        })
      );
      
    } else {

      if (token) {
        request = req.clone({
          setHeaders: {
            authorization: `Bearer ${token}`
          }
        });
      }

      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          console.log('error', err.status);

          if (err.status == 401) {
            console.log('error 401!');

            this.dialog.closeAll();
            localStorage.removeItem('token');
            localStorage.removeItem('products');
            localStorage.removeItem('coupon');
            localStorage.removeItem('xtrack');
            localStorage.removeItem('recommended');
            localStorage.removeItem('customerEmail');
            localStorage.removeItem('maintenanceId');
            localStorage.removeItem('products');
            localStorage.removeItem('productsVersion');
            localStorage.removeItem('customerId');
            localStorage.removeItem('url');
            sessionStorage.setItem('ShowModal', 'true');

            this.router.navigateByUrl('/login');
          }
          if (err.status == 500) {
            this.dialog.closeAll();
            console.log('error 500!');

            this.router.navigateByUrl('/error-500');
          }
          if (err.status == 404) {
            this.dialog.closeAll();
            console.log('error 404!');

            this.router.navigateByUrl('/error-404');
          }
          return throwError(err);

        })
      );
    }
  }

}
