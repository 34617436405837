import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-error-500',
    templateUrl: './error-500.component.html',
    styleUrls: ['./error-500.component.scss']
})
export class Error500Component implements OnInit, OnDestroy {

    assetsHref = environment.assetsHref;

    resources: any;

    constructor() {}

    ngOnInit(): void {

    }

    ngOnDestroy() {

    }

}
